import { Box, Card, LinearProgress, Stack, Typography } from '@mui/material';
import { useBillingSso } from 'api/auth';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import Plus from 'component/new_design/icons/Plus.svg?react';
import { LoadingButton } from '@mui/lab';

export interface MetricsCardProps {
  readonly title: string;
  readonly usage: number | undefined;
  readonly limit: number | undefined;
  readonly formatValue?: (value: number | undefined, isLimit: boolean) => string;
  readonly offerUpgrade?: boolean;
  readonly packageId: number;
}

const defaultFormatValue = (value: number | undefined) => (value ? value.toFixed(0) : '-');

const RED_PROGRESS_THRESHOLD = 90;

export const MetricsCard = ({
  title,
  usage,
  limit,
  formatValue = defaultFormatValue,
  offerUpgrade = false,
  packageId,
}: MetricsCardProps) => {
  const { t } = useTranslation();
  const billing = useBillingSso();
  const { enqueueSnackbar } = useSnackbar();

  const usedPercentage = ((usage || 0) / (limit || 1)) * 100;
  const progressColor = usedPercentage > RED_PROGRESS_THRESHOLD ? 'error' : 'primary';

  const handleUpgrade = async () => {
    const { data } = await billing.mutateAsync({
      destination: 'upgrade.php',
      params: `type=package&id=${packageId}`,
    });
    if (data.result?.link) {
      window.location.href = data.result?.link;
    } else {
      enqueueSnackbar(null, { variant: 'error' });
    }
  };

  return (
    <Card>
      <Stack p={5} gap={3} justifyContent="space-between">
        <Stack justifyContent="space-between" direction="row">
          <Typography variant="body2" sx={{ color: 'greys.500' }}>
            {title}
          </Typography>
          {offerUpgrade ? (
            <LoadingButton
              onClick={handleUpgrade}
              loading={billing.isPending}
              variant="text"
              size="small"
              startIcon={<Plus />}
            >
              {t('upgrade_plan')}
            </LoadingButton>
          ) : null}
        </Stack>
        <Box>
          <Typography component="span" variant="h4">
            {formatValue(usage, false)}
          </Typography>
          <Typography component="span" variant="h4" sx={{ color: 'greys.300' }}>
            /{formatValue(limit, true)}
          </Typography>
        </Box>
        {limit ? (
          <LinearProgress variant="determinate" value={usedPercentage} color={progressColor} />
        ) : (
          <LinearProgress variant="determinate" value={100} color="secondary" />
        )}
      </Stack>
    </Card>
  );
};
