import { Card, CardHeader } from '@mui/material';
import { CardList } from 'component/new_design/base/CardList';
import { useTranslation } from 'react-i18next';
import Ticket from 'component/new_design/icons/Ticket.svg?react';
import { EmptyState } from '../base/EmptyState';

// TODO add data when support tickets API is ready
export const DashboardSupport = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader title={t('recent_support_tickets')} />
      <CardList
        items={[]}
        renderItem={() => null}
        renderAction={() => null}
        emptyState={<EmptyState icon={<Ticket />} title={t('no_support_tickets')} />}
      />
    </Card>
  );
};
