import { Skeleton } from '@mui/material';
import { MetricsGrid } from './MetricsGrid';

export const MetricsSkeleton = () => {
  return (
    <MetricsGrid>
      <Skeleton variant="rounded" sx={{ height: 'unset' }} />
      <Skeleton variant="rounded" sx={{ height: 'unset' }} />
      <Skeleton variant="rounded" sx={{ height: 'unset' }} />
      <Skeleton variant="rounded" sx={{ height: 'unset' }} />
    </MetricsGrid>
  );
};
