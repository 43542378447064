import { Card, CardHeader, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FeedResponse, useDashboardFeed } from 'api/dashboard';
import { CardList } from 'component/new_design/base/CardList';
import ChevronRight from 'component/new_design/icons/ChevronRight.svg?react';
import AnnotationStar from 'component/new_design/icons/AnnotationStar.svg?react';
import { EmptyState } from '../base/EmptyState';

const UpdateItem = ({ title, date_modified }: FeedResponse['items'][number]) => {
  const { i18n } = useTranslation();

  const formattedDate = new Date(date_modified).toLocaleString(i18n.language, {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return (
    <>
      <Typography
        variant="body2"
        fontWeight={600}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {title}
      </Typography>
      <Typography variant="body2">{formattedDate}</Typography>
    </>
  );
};

export const DashboardUpdates = () => {
  const { data, isLoading } = useDashboardFeed();
  const { t } = useTranslation();

  const items = data?.data.items.slice(0, 5) || [];

  return (
    <Card>
      <CardHeader title={t('platform_updates')} />
      <CardList
        items={items}
        loading={isLoading}
        renderItem={item => <UpdateItem {...item} />}
        renderAction={item => (
          <IconButton href={item.url} target="_blank">
            <ChevronRight />
          </IconButton>
        )}
        emptyState={
          <EmptyState
            icon={<AnnotationStar />}
            title={t('no_data_yet')}
            description={t('there_are_no_new_updates')}
          />
        }
      />
    </Card>
  );
};
