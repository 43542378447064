import { Skeleton } from '@mui/material';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { ErrorInfo, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

interface SuspenseLoaderProps {
  readonly loading?: React.ReactNode;
  readonly error?: React.ReactNode;
  readonly errorMessage?: string;
  readonly children: React.ReactNode;
}

const LoadingFallback = () => <Skeleton variant="rounded" sx={{ height: 'unset' }} />;

export const SuspenseLoader = ({ loading, errorMessage, error, children }: SuspenseLoaderProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleError = (error: Error, info: ErrorInfo) => {
    enqueueSnackbar(errorMessage || error.message, { variant: 'error' });
    console.error(error, info);
  };

  return (
    <ErrorBoundary fallbackRender={() => error} onError={handleError}>
      <Suspense fallback={loading || <LoadingFallback />}>{children}</Suspense>
    </ErrorBoundary>
  );
};
